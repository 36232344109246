@import "../website-v2/_variables.less";

// Mixins
@import '../shared/mixins/_gradient-border.less';

// cards.less will be refactored. This should be source for all card components.
.card {
	--card-border-radius: 1.25rem;

	&__badge {
		--badge-height: 2rem;
		--badge-color: var(--cyan-300);
		--badge-border-radius: calc(var(--badge-height)/2);
		border: 0.0625rem solid var(--badge-color);
		border-radius: var(--badge-border-radius);
		width: fit-content;

		@media screen and (max-width: @screen-md) {
			align-self: center;
		}

		&-content {
			display: flex;
			justify-content: center;
			align-items: center;
			max-height: var(--badge-height);
			font-size: var(--octo-font-size-extra-small);
			letter-spacing: 0.07644rem;
			color: var(--badge-color);
			padding: 0.4rem 1.5rem;
			text-transform: uppercase;
			font-weight: 800;
			background-color: var(--background-secondary);
			border-radius: var(--badge-border-radius);
		}
	}

	&__heading,
	&__subheading {
		font-size: var(--h5);
		margin: 0;
		color: var(--octo-color-text-heading);

		&--large {
			font-size: var(--h3);
		}
	}

	&__subheading {
		color: var(--card-subheading);

		@media screen and (max-width: @screen-md) {
			font-size: var(--h6);
		}
	}

	&__paragraph {
		font-size: var(--octo-font-size-small);

		p {
			margin: 0;
			padding: 0;
			color: var(--octo-color-text-normal);
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: var(--space-16);
		padding: var(--space-32);
		flex: 1;
		height: 100%;

		.underlined-arrow {
			width: fit-content;
		}
	}

	&__cta {
		display: flex;
		flex-direction: column;
		margin-top: auto;

		&-label {
			color: var(--octo-color-text-normal);
			font-weight: 700;
			font-size: var(--octo-font-size-small);
		}

		&-buttons {
			display: flex;
			flex-wrap: wrap;
			gap: var(--space-6);
			margin-bottom: var(--space-16);
		}
	}

	&__background {
		background-color: var(--background-secondary);
		flex-grow: 1;
	}

	// Simple variant
	&--simple {
		box-shadow: var(--card-shadow);
		border-radius: var(--card-border-radius);
		overflow: hidden;
		height: 100%;

		.stripe-navy & {
			--border-width: 0.0625rem;

			.gradient-border(var(--card-border-radius), var(--border-width), var(--navy-blue-gradient));
		}
	}

	&--with-icon {
		.card__background {
			align-items: center;
			gap: var(--space-24);

			.image-wrapper {
				max-width: 4rem;
				max-height: 4rem;
			}

			.card__content {
				padding: var(--space-0);
			}
		}
	}

	&--transparent {
		border: 0.0625rem solid var(--input-border);
		background: transparent;
		box-shadow: none;

		.card__background {
			justify-content: center;
			background: transparent;

			.image-wrapper {
				width: 2.5rem;
				height: 2.5rem;
				margin: 0 auto;

				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}
		}

		.card__content {
			height: auto;
			flex: 0;
			align-items: center;

			.card__cta {
				margin-top: var(--space-0);
			}
		}
	}

	&--icon-left {
		.card__background {
			align-items: start;

			.image-wrapper {
				border-radius: 0;
				
				img {
					object-fit: contain;
					height: 100%;
				}
			}
		}
	}

	&--image-row,
	&--image-column {
		.card__background {
			display: flex;
			flex-grow: 1;
		}

		&.card--simple {
			.card__background {
				padding: var(--space-4);
			}
		}

		&.card--with-icon {
			.card__background {
				padding: var(--space-32);
			}
		}

		.image-wrapper {
			margin: 0;
			overflow: hidden;

			img {
				object-fit: cover;
				object-position: center;
			}
		}

		.card__content {
			gap: var(--space-24);
		}
	}

	&--image-row {
		.card__background {
			flex-direction: row;

			@media screen and (max-width: @screen-md) {
				flex-direction: column;
			}
		}

		.image-wrapper {
			border-radius: var(--card-border-radius) 0 0 var(--card-border-radius);
			max-width: 17.75rem;
			width: 100%;

			@media screen and (max-width: @screen-md) {
				border-radius: var(--card-border-radius);
				max-width: 100%;
			}

			img {
				height: 100%;
				width: 100%;

				@media screen and (max-width: @screen-md) {
					height: 16rem;
				}
			}
		}
	}

	&--image-column {
		.card__background {
			flex-direction: column;
		}

		.image-wrapper {
			border-radius: var(--card-border-radius);

			img {
				height: 16rem;
			}
		}
	}
}