.gradient-border(@border-radius, @border-width, @gradient) {
	position: relative;
	z-index: 1;
	overflow: visible;
	border-radius: @border-radius;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% + 2 * @border-width);
		height: calc(100% + 2 * @border-width);
		background: @gradient;
		border-radius: @border-radius;
		margin: calc(-1 * @border-width);
		z-index: -2;
	}

	&>*:first-child {
		// Adjusting border radius to create smooth corner
		border-radius: calc(@border-radius - @border-width);
	}
}